.cw-alert {
	color: rgb(13, 60, 97);
	background-color: rgb(232, 244, 253);
	padding: 6px 16px;
	margin: 10px;
	font-size: 0.875rem;
	font-weight: 400;
	line-height: 1.43;
	border-radius: 4px;
	letter-spacing: 0.01071em;
	align-items: center;
	display: flex;

	span {
		margin: 0px 10px;
		white-space: initial;
	}
}

.cw-alert-info {
	color: rgb(13, 60, 97);
	background-color: rgb(232, 244, 253);

	svg {
		background-color: rgb(13, 60, 97);
	}
}

.cw-alert-error {
	color: rgb(97, 26, 21);
	background-color: rgb(253, 236, 234);
	svg {
		background-color: rgb(97, 26, 21);
	}
}

.cw-alert-warning {
	color: rgb(102, 60, 0);
	background-color: rgb(255, 244, 229);
	svg {
		background-color: rgb(102, 60, 0);
	}
}

.cw-alert-success {
	color: rgb(30, 70, 32);
	background-color: rgb(237, 247, 237);
	svg {
		background-color: rgb(30, 70, 32);
	}
}
