.mui-chip_selected {
	background-color: #64b5f6 !important;
}

.mui-chip_disabled {
	cursor: not-allowed !important;
}

.mui-chip__label {
	font-size: 20px !important;
}

.mui-avatar {
	margin: 10 !important;
}

.mui-avatar_selected {
	color: #64b5f6 !important;
}

.button_label__white {
	color: #ffffff !important;
}

.switch_label__bold {
	font-weight: bold !important;
}

.select_icon__fullWidth {
	padding: 0 !important;
	width: 100% !important;
}

.btn_speedDial {
	flex-direction: row !important;
}

.btn_speedDial_product_menu {
	position: absolute;
	top: 45px;
}

.btn__add_product_line {
	position: absolute !important;
	left: -56px;
	margin: 8px !important;
}
.btn__add_product_line_disabled {
	position: absolute !important;
	left: -56px;
	margin: 8px !important;
	opacity: 0.45 !important;
	cursor: not-allowed !important;
}
.btn__add_product_menu {
	position: absolute !important;
	top: -54px;
	left: 0px;
	margin: 8px !important;
}

.select__multiple__selected {
	background-color: rgba(0, 0, 0, 0.14) !important;
}

.badge-reweight__remove {
	top: -8px !important;
	right: -8px !important;
	width: 18px !important;
	height: 18px !important;
	display: flex !important;
	cursor: pointer;
	opacity: 0 !important;
	transition: opacity 0.5s linear;
}

#product-category-sortable-product .stylizedItem:hover .badge-reweight__remove,
#product-category-list .stylizedItem:hover .badge-reweight__remove {
	opacity: 1 !important;
	transition: opacity 0.5s linear;
}

.MuiModal-hidden-25,
.jss25,
.MuiModal-hidden-218,
.jss218 {
	visibility: visible !important;
}

.productMenuFormVerificationCard {
	overflow: auto !important;
}

#supplement-popover {
	visibility: hidden;
}

.supplement-popover__paper {
	visibility: visible;
	opacity: 0.9;
	background-color: #616161 !important;
	color: #fff;
	padding: 3px !important;
	font-size: 12px !important;
}
