$icomoon-font-path: '../assets/font' !default;

$icon-fleche: '\e900';
$icon-logo: '\e901';
$icon-menu: '\e902';
$icon-notifs: '\e903';
$icon-placesend: '\e904';
$icon-plus: '\e905';
$icon-produits: '\e906';
$icon-restaurer: '\e907';
$icon-supplement: '\e908';
$icon-tax: '\e909';
$icon-annuler: '\e90a';
$icon-categorie: '\e90b';
$icon-close: '\e90c';
$icon-editer: '\e90d';
$icon-categorieprix: '\e90f';
$icon-deploiement: '\e90e';
$icon-salles: '\e910';
$icon-master: '\e976';
$icon-zone: '\e947';
$icon-company: '\e911';
$icon-date: '\e953';
