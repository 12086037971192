@import 'variables';

@font-face {
	font-family: 'icon-font';
	src: url('#{$icomoon-font-path}/icon-font.eot?dod50a');
	src: url('#{$icomoon-font-path}/icon-font.eot?dod50a#iefix')
			format('embedded-opentype'),
		url('#{$icomoon-font-path}/icon-font.ttf?dod50a') format('truetype'),
		url('#{$icomoon-font-path}/icon-font.woff?dod50a') format('woff'),
		url('#{$icomoon-font-path}/icon-font.svg?dod50a#icon-font') format('svg');
	font-weight: normal;
	font-style: normal;
}

[class^='icon-'],
[class*=' icon-'] {
	/* use !important to prevent issues with browser extensions that change fonts */
	font-family: 'icon-font' !important;
	speak: none;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;

	/* Better Font Rendering =========== */
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.icon-fleche {
	&:before {
		content: $icon-fleche;
	}
}
.icon-logo {
	&:before {
		content: $icon-logo;
	}
}
.icon-menu {
	&:before {
		content: $icon-menu;
	}
}
.icon-notifs {
	&:before {
		content: $icon-notifs;
	}
}
.icon-placesend {
	&:before {
		content: $icon-placesend;
	}
}
.icon-plus {
	&:before {
		content: $icon-plus;
	}
}
.icon-produits {
	&:before {
		content: $icon-produits;
	}
}
.icon-restaurer {
	&:before {
		content: $icon-restaurer;
	}
}
.icon-supplement {
	&:before {
		content: $icon-supplement;
	}
}
.icon-tax {
	&:before {
		content: $icon-tax;
	}
}
.icon-annuler {
	&:before {
		content: $icon-annuler;
	}
}
.icon-categorie {
	&:before {
		content: $icon-categorie;
	}
}
.icon-close {
	&:before {
		content: $icon-close;
	}
}
.icon-editer {
	&:before {
		content: $icon-editer;
	}
}
.icon-categorieprix {
	&:before {
		content: $icon-categorieprix;
	}
}
.icon-salles {
	&:before {
		content: $icon-salles;
	}
}
.icon-deploiement {
	&:before {
		content: $icon-deploiement;
	}
}

.icon-master {
	&:before {
		content: $icon-master;
	}
}

.icon-company {
	&:before {
		content: $icon-company;
	}
}

.icon-zone {
	&:before {
		content: $icon-zone;
	}
}

.icon-calendar {
	&:before {
		content: $icon-date;
	}
}
